import React, {useState, useEffect} from 'react';
import './Navigation.css'
import { Link }from 'react-router-dom';
import { Image } from '../core';
import logo from '../../assets/images/logo.png';
import strangeLogo from '../../assets/images/sticker.png';
import finalLogo from '../../assets/images/final-logo.png';
import menu from '../../assets/images/menu-icon.png';
import cross from '../../assets/images/cross.png'
import sticker from '../../assets/images/sticker.png'

export const Navigation = ({isActive, isScrolled}) => {
  const [sidebarActive, setSidebarActive] = useState(false)
  useEffect(() => {
    setSidebarActive(isActive)
  }, [isActive])
  return (
    <>
    <div className={`duration-400 transition w-full border-b border-b-strange-green lg:border-b-transparent h-[54px] lg:h-[12px] slide-top fixed z-[9999999999] bg-[#ffffee] lg:bg-transparent`}>
    <Link className={'w-[54px] h-[54px] lg:w-[64px] lg:h-[64px] top-0 right-0 lg:right-2 lg:top-2 fixed shadow-sm'} to={'/'}><img className={'w-full h-auto'} src={finalLogo} /></Link>
    <div onClick={() => setSidebarActive(!sidebarActive)} className={'cursor-pointer z-40 relative md:right-[calc(100vw-96px)] right-[calc(100vw-64px)] top-2 md:top-0 float-right flex items-center  px-[2px] py-[3px]'}>
    <img onClick={() => setSidebarActive(!sidebarActive)} src={menu} className={' cursor-pointer relative z-40 lg:w-[92px] lg:h-[92px] w-[64px] h-[64px] strange-green-filter sm:translate-x-2 md:-translate-x-2 translate-x-2 sm:-translate-y-4 md:-translate-y-2 -translate-y-4 lg:-translate-y-2'}/>
    </div>
      <nav className={'max-w-screen-xl relative mx-auto flex justify-between fade-in '}>
        {window.location.pathname !== '/' && <div className={'flex justify-between w-[160px] lg:pt-2 opacity-0'}>
          <Link to={'/'}><Image src={logo} /></Link>
        </div>}
      </nav>
      <div className={`bg-[#ffffee]/90 blur-panel border-r border-strange-green w-[320px] h-[100vh] top-0 transition duration-300 ease-in-out z-50 fixed right-[100vw] z-[99999999999999] ${sidebarActive ? 'translate-x-[320px]' : '-translate-x-[320px]'}`}>
      <div className={'mt-4 flex justify-end'} onClick={() => setSidebarActive(false)}>
        <p className={'font-print text-strange-green text-md italic cursor-pointer'}>close </p><img src={cross} className="w-8 h-8 strange-green-filter cursor-pointer" />
      </div>
        <ul className={'text-strange-green font-print text-lg ml-6 italic mt-16'}>
        <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/'}>Home</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/artists'}>Artists</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/discography'}>Discography</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/events'}>Events</Link></li>
          <li onClick={() => setSidebarActive(false)} className={'px-4 py-2'}><Link to={'/about'}>About</Link></li>
        </ul>
      </div>
    </div>
    </>
  )
}

export default Navigation;
