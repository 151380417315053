import logo from './logo.svg';
import './App.css';
import {useState} from 'react';
import { Navigation, Footer, Player } from './components';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import {Home, About, Artists, Events, Releases, Single } from './pages';


function App() {

  const [playerAudio, setPlayerAudio] = useState(null)
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [scrolledDown, setScrolledDown] = useState(false);
  const [hasSeenIntro, setHasSeenIntro] = useState(false)

  const handleMenuIconClick = (e) => {
    setIsMenuActive(!isMenuActive)
    // alert(e)
  }

  return (
    <>
    <div className="main overflow-x-hidden">
    <Router>
      {/*showMobileHeaderMenu && <Navigation />*/}
      <Navigation isScrolledDown={scrolledDown} isActive={isMenuActive} />
      <Routes>
        <Route path={'/'} element={<Home hasSeenIntro={hasSeenIntro} handleHasSeenIntro={setHasSeenIntro} handleMenuIconClick={handleMenuIconClick} handleScrolledDown={setScrolledDown} handleAudio={setPlayerAudio} />} />
        <Route path={'/artists'} element={<Artists />} />
        <Route path={'/events'} element={<Events />} />
        <Route path={'/discography'} element={<Releases handleAudio={setPlayerAudio} />} />
        <Route path={'/about'} element={<About />} />
        <Route path={'/:slug'} element={<Single />} />
      </Routes>
    </Router>
    </div>
    <div className={'w-full'}>
    <Footer />
    <Player audio={playerAudio} clearAudio={() => setPlayerAudio(null)}/>
    </div>
    </>
  );
}

export default App;
