import {useState, useEffect} from 'react'
import placeholder from '../assets/images/43-placeholder.png'
import DOMPurify from 'dompurify';
import {getPage, getPosts} from '../api/api'
import SC from '../assets/images/SC.png';
import BC from '../assets/images/BC.png';
import IG from '../assets/images/IG.png';
import FB from '../assets/images/FB.png';

export const About = () =>  {

  const PAGE_ID = 245;


  const [aboutPage, setAboutPage] = useState(null)

  const getAboutPageContent = () => {
    getPage(PAGE_ID).then(result => {
      setAboutPage({
        content: result.content.rendered,
        desktop: result.acf['desktop_background'],
        mobile: result.acf['mobile_background'],
        section_one: result.acf['section_one'],
        section_two: result.acf['section_two'],
        section_three: result.acf['section_three'],
      })
    })
  }



  useEffect(() => {
    getAboutPageContent()
    window.scrollTo(0, 0);
  }, [])

  return (
    <div className={'md:min-h-[100vh] flex justify-center w-full items-center'}>
    {aboutPage && <div className={'hidden md:block fixed w-full h-[100vh] overflow-hidden'}><img src={aboutPage.desktop} className={'w-full absolute left-0 top-0 -z-[0]'} /></div>}
    {aboutPage && <div className={'block md:hidden fixed w-full h-[100vh] overflow-hidden'}><img src={aboutPage.mobile} className={'w-full absolute left-0 top-0 -z-[0]'} /></div>}
    <div className={'md:mt-32 xl:mt-18'}>
      <div className={'md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl mx-auto grid grid-cols-12'}>
        <div className={'col-span-12 md:col-span-8'}>

          <h1 className={'z-[99] relative text-6xl font-scum md:border md:border-strange-green w-[calc(100%-80px)] ml-[40px] py-2 px-2 md:w-auto text-strange-green inline-block md:ml-2 md:bg-[#ffffee] mt-24 md:mt-0 mb-8'}>About</h1>
          <h2 className={'w-[calc(100%-80px)] ml-[40px] relative z-[99] inline-block font-print px-4 py-2 md:hidden border border-strange-green bg-[#ffffee] my-[2rem'}>Intended to mock, question, decry, rot and dwell.</h2>
          {aboutPage &&
          <>
            <div className={'bg-[#ffffee] about z-[99] relative blur-panel-max border border-strange-green font-print w-[calc(100%-80px)] ml-[40px] md:w-full max-w-screen-lg my-4 md:px-4 md:py-2'}>
              <p className={'about-content'} dangerouslySetInnerHTML={{__html: aboutPage.section_one}}></p>
            </div>
            <div className={'md:hidden block bg-[#ffffee] about z-[99] relative blur-panel-max border border-strange-green font-print w-[calc(100%-80px)] ml-[40px] md:w-full max-w-screen-lg my-4 md:p-2'}>
              <p className={'about-content'} dangerouslySetInnerHTML={{__html: aboutPage.section_two}}></p>
            </div>
            <div className={'md:hidden block bg-[#ffffee] about z-[99] relative blur-panel-max border border-strange-green font-print w-[calc(100%-80px)] ml-[40px] md:w-full max-w-screen-lg my-4 md:p-2'}>
              <p className={'about-content'} dangerouslySetInnerHTML={{__html: aboutPage.section_three}}></p>
            </div>
           </>
          }
        </div>
        <div className={'col-span-12 md:col-span-4'}>

          <h2 className={'hidden relative z-[99] px-2 py-2 mr-16 text-center md:inline-block font-print border border-strange-green blur-panel-max bg-[#ffffee]'}>Intended to mock, question, decry, rot and dwell.</h2>
          <div className={'md:mt-32 md:bg-[#ffffee] flex relative z-[99] md:flex-col md:justify-start justify-between md:mb-4 gap-1 md:w-auto w-[calc(100%-80px)] md:ml-0 ml-[40px] md:float-right md:border md:border-strange-green px-1 py-2 md:py-4'}>
                <div className={'w-16 h-16'}><a href={'https://soundcloud.com/strangetherapy'} target={'_blank'}><img src={SC}/></a></div>
                <div className={'w-16 h-16'}><a href={'https://www.facebook.com/StrangeTherapy/'} target={'_blank'}><img src={FB}/></a></div>
                <div className={'w-16 h-16'}><a href={'https://www.instagram.com/strangetherapytapes/'} target={'_blank'}><img src={IG}/></a></div>
                <div className={'w-16 h-16'}><a href={'https://strangetherapy.bandcamp.com/music'} target={'_blank'}><img src={BC}/></a></div>
              </div>
        </div>
        <div className={'col-span-4'}>

        </div>
        {aboutPage &&
        <>
        <div className={'hidden md:block col-span-8'}>
          <div className={' bg-[#ffffee] about z-[99] relative blur-panel-max border border-strange-green font-print w-[calc(100%-80px)] ml-[40px] md:w-full max-w-screen-lg my-4 md:px-4 md:py-2'}>
            <p className={'about-content'} dangerouslySetInnerHTML={{__html: aboutPage.section_two}}></p>
          </div>
        </div>

        <div className={'hidden md:block col-span-8'}>
          <div className={'hidden md:inline-block  about z-[99] relative  font-print w-[calc(100%-80px)] ml-[40px] md:w-full max-w-screen-lg my-4 flex'}>
            <p className={'about-content inline-block bg-[#ffffee] border border-strange-green md:px-4 md:py-2'} dangerouslySetInnerHTML={{__html: aboutPage.section_three}}></p>
          </div>
        </div>
        </>
        }
      </div>
      </div>
    </div>
  );
}

export default About;
