import React, {useState, useEffect} from 'react';
import {useHref} from 'react-router-dom'
import {format} from 'date-fns'
import { getPostBySlug } from '../api/api';
import { Image } from '../components/index'

export const Single = () => {
  const history = useHref()
  const [post, setPost] = useState(null)
  useEffect(() => {
      const slug = history.split('/').pop()
      console.log(slug)
      getPostBySlug(slug).then(result => {
        console.log(result)
        setPost({
            id: result[0].id,
            date: result[0].date,
            monthYear: format(new Date(result[0].date), "yy-MM"),
            title: result[0].title.rendered,
            excerpt: result[0].excerpt.rendered,
            content: result[0].content.rendered,
            slug: result[0].slug,
            // banner: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.source_url,
            // thumbnail: result[0]._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
        })
      })
      window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <div className={'main min-h-[100vh] bg-charcoal pb-16'}>
      { post &&
        <div className={'max-w-screen-lg mx-auto p-4'}>
          {/*<Image src={post.banner} />*/}
          <h1 className={'font-primary text-coral text-2xl md:text-6xl pt-2 pb-2 mt-8'} dangerouslySetInnerHTML={{__html: post?.title}}></h1>
          <h2 className={'font-secondary text-white md:text-xl text-2xl pt-2 pb-4'} dangerouslySetInnerHTML={{__html: post?.excerpt }}></h2>
          <div className={'blog-content font-secondary text-white py-4'} dangerouslySetInnerHTML={{__html: post.content }}></div>
        </div>
      }
      </div>
    </>
  )
}

export default Single;
