import React, {useState, useEffect} from 'react';
import {Link } from 'react-router-dom';
import {getPostById} from '../../api/api'

export const CarouselInfo = ({slide}) => {
  const [featuredRecords, setFeaturedRecords] = useState([])

  useEffect(() => {
    setFeaturedRecords([])
    if(slide && slide.featured_records) {
      getPostById(slide.featured_records.map(f => f.ID))
        .then(res => setFeaturedRecords(res))
    }

  }, [slide])
  return (
    <>
      <div className={'absolute w-[100vw] md:w-[50vw] top-[0] pt-24 md:top-0 min-h-[100vh] md:h-[100vh] flex justify-center right-0 bg-[#fffd] border-t md:border-t-transparent md:border-l border-strange-green bg-[#ffffee] z-[2] '}>
      { slide == null &&
        <div className={'h-[calc(100%-92px)] font-print text-2xl text-strange-green flex justify-center items-center w-full'}>
          <p>Click on {window.location.pathname.includes('/artists') ? 'an artist' : 'a release'} to see more info</p>
        </div>
      }
      { slide &&
        <>
          <div className={'px-8 pb-16 mt-0 pt-4 md:mt-[0px] max-h-[calc(100vh-96px)] overflow-scroll'}>
            <h3 className={'font-print text-4xl mb-2 text-strange-green'} dangerouslySetInnerHTML={{__html: slide.artist ? slide.artist : slide.title}}></h3>
            <h4 className={'font-print text-2xl md:text-4xl mb-2 md:mb-8 text-[#666]'} dangerouslySetInnerHTML={{__html: slide.album}}></h4>

            <p className={'font-print text-md md:text-xl wp-blog-content'} dangerouslySetInnerHTML={{__html: slide.content}}></p>
            <div className={'my-4'} dangerouslySetInnerHTML={{__html: slide.bandcamp_embed}}></div>
            {slide.intro_photo && <div className={'w-full md:max-w-[calc(100%-10rem)]'}><img className={'w-full h-auto'} src={slide.intro_photo}/></div>}
            { featuredRecords.length !== 0 && <><br/><h2 className={'font-print py-2 pt-6 border-b border-b-strange-green text-2xl w-full inline'}>Releases By <span dangerouslySetInnerHTML={{__html: slide.title}}></span></h2></> }
            <div className={'grid grid-cols-2 md:grid-cols-4 gap-4 mt-12 pb-8'}>
              {featuredRecords && featuredRecords.map(post => {
                return (
                  <Link to={`/discography#${post.slug}`}>
                  <div className={'col-span-1 cursor-pointer slide-fade-up'}>
                    {post._embedded['wp:featuredmedia'][0].source_url && <img src={post._embedded['wp:featuredmedia'][0].source_url} />}
                    <p className={'text-sm mt-2 font-print font-bold'} dangerouslySetInnerHTML={{__html: post.title.rendered}}></p>
                  </div>
                  </Link>
                )
                })
              }
            </div>
          </div>
        </>
      }
      </div>
    </>
  )
}

export default CarouselInfo
