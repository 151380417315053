import {useState, useEffect} from 'react'
import DOMPurify from 'dompurify';
import {getPosts} from '../api/api'
import {CarouselSlider, CarouselInfo} from '../components/index';
import sticker from '../assets/images/sticker.png'

export const Artists = () =>  {

    const CATEGORY_ID = 2;
    const [releases, setReleases] = useState(null)
    const [savedReleases, setSavedReleases] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [activeListIndex, setActiveListIndex] = useState(null)
    const [listViewActive, setListViewActive] = useState(true);

    const URL_HASH = window.location.hash.replace(/^#/, '')

    const getReleases = (page) => {
      getPosts(page, CATEGORY_ID).then(result => {
        let data = result.data.map(post => ({
          id: post.id,
          title: post.title.rendered,
          subtitle: post.acf.post_subtitle,
          excerpt: post.content.excerpt,
          content: post.content.rendered,
          thumb: post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : sticker,
          slug: post.slug,
          album: post.acf.release_album,
          artist: post.acf.release_artist,
          video_embed: post.acf.release_video,
          bandcamp_embed: post.acf.bandcamp_embed,
          featured_records: post.acf.featured_records
        }))

        if(!savedReleases) {
          setSavedReleases(data)
        } else {          
          processArtistsForSlideshow(data, URL_HASH)
        }
      })
    }

    const processArtistsForSlideshow = (data, hash) => {
      const selectedIndex = data.map(r => r.slug).indexOf(hash)
      if(!!window.location.hash.replace(/^#/, '') && activeIndex == null) {
        setActiveIndex(0)
      } else {
        setActiveIndex(activeIndex !== null ? 0 : null)
      }
      data = [...data.splice(selectedIndex), ...data.splice(0, selectedIndex)]
      setReleases(data)
    }

    useEffect(() => {
      getReleases(1)
      window.scrollTo(0, 0);
      if(!!window.location.hash.replace(/^#/, '')) {
        setListViewActive(false)
      }
    }, [])

    // SET URL WHEN CHANGED
    useEffect(() => {
      if(releases) {
        window.location.hash = releases[activeIndex].slug;
      }
    }, [activeIndex, activeListIndex])

    useEffect(() => {
      if(savedReleases) {
        processArtistsForSlideshow([...savedReleases], URL_HASH)
      }
    }, [savedReleases])

    const handleSelectFromList = (i) => {
      processArtistsForSlideshow([...savedReleases], savedReleases[i].slug)
      setActiveIndex(0)
      setActiveListIndex(i)
      setListViewActive(false);
    }


    return (
      <div className={'min-h-[100vh] w-full relative'}>
        {!listViewActive && <p onClick={() => setListViewActive(true)} className={'cursor-pointer z-[999999999999] md:right-[calc(50vw+3rem)] left-8  top-[68px] border border-strange-green/25 py-1 px-2  md:top-5 text-strange-green absolute text-md md:text-xl md:w-[240px] md:left-auto md:right-[50vw+rem] md:text-center uppercase font-bold font-print'}>see all artists</p>}
        {listViewActive &&
          <div className={'w-[100vw] md:w-[50vw] flex items-center justify-center h-full fixed bg-[#ffffee]/25 z-[999]'}>
            <div className={'releases-list overflow-y-scroll max-h-[75vh] grid grid-cols-1 xl:grid-cols-3 grid-cols-2 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 gap-6 px-3 xs:px-4 sm:px-[56px] bg-[#ffffee] md:bg-[#ffffee]/50 border border-strange-green pt-16 xl:pt-16 2xl:pt-32 pb-8 xl:pb-16 2xl:pb-32 mx-4 xs:mx-6 sm:mx-8 w-full md:w-auto'}>
            {savedReleases && savedReleases.map((release, i) => {
              return (
                <div onClick={() => handleSelectFromList(i)}  className={`cursor-pointer`}>
                <div className={"w-full pt-[100%] relative"}>
                  <img className={'absolute inset-0 w-full h-full object-cover object-center'} src={release.thumb} alt={release.title} />
                </div>
                  <p className={`cursor-pointer hover:text-strange-green font-print italic px-1 py-1 mt-1 bg-[#ffffee]  text-center text-lg`} dangerouslySetInnerHTML={{__html: release.title}}></p>
                </div>
              )
            })}
            </div>
          </div>
        }
        {releases && <CarouselInfo slide={activeIndex !== null ? releases[activeIndex] : null} />}
        <div className={'carousel-container w-full min-h-[100vh] mx-auto '}>
        {releases && !listViewActive && <CarouselSlider carousel={releases} currentSlide={activeIndex} handleCurrentSlide={setActiveIndex}/>}
        </div>
      </div>
    );
  }

export default Artists;
