import React from 'react';
import './Image.css'
import placeholder from '../../../assets/images/43-placeholder.png'

export const Image = ({src, onLoad}) => {
  return (
    <>
      <div className={'image-container'}>
        <img onLoad={() => onLoad ? onLoad : null} src={src ? src : placeholder} />
      </div>
    </>
  )
}

export default Image;
