import React from 'react';

export const Footer = () => {
  return (
    <>
    {/*<div className="bg-white fixed bottom-0 w-full">
      <p className={'w-full text-center'}>Strange Therapy</p>
    </div>*/}
    </>
  )
}

export default Footer;
