import {useState, useEffect} from 'react'
import DOMPurify from 'dompurify';
import {CarouselSlider, CarouselInfo} from '../components/index';
import {getPosts} from '../api/api'
import { act } from 'react-dom/test-utils';

export const Releases = () =>  {

  const CATEGORY_ID = 5;


  const [releases, setReleases] = useState(null)
  const [savedReleases, setSavedReleases] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeListIndex, setActiveListIndex] = useState(0)
  const [listViewActive, setListViewActive] = useState(true);

  const URL_HASH = window.location.hash.replace(/^#/, '')

  const getReleases = (page) => {
    getPosts(page, CATEGORY_ID).then(result => {
      let data = result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        subtitle: post.acf.post_subtitle,
        excerpt: post.content.excerpt,
        content: post.content.rendered,
        thumb: post._embedded['wp:featuredmedia'][0].source_url,
        slug: post.slug,
        album: post.acf.release_album,
        artist: post.acf.release_artist,
        video_embed: post.acf.release_video,
        bandcamp_embed: post.acf.bandcamp_embed,
        intro_photo: post.acf.introduction_image
      }))
      if(!savedReleases) {
        setSavedReleases(data)
      } else {
        processReleasesForSlideshow(data, URL_HASH)
      }
    })
  }

  const processReleasesForSlideshow = (data, hash) => {
    const selectedIndex = data.map(r => r.slug).indexOf(hash)
    if(!!window.location.hash.replace(/^#/, '') && activeIndex == null) {
      setActiveIndex(0)
    } else {
      setActiveIndex(activeIndex !== null ? 0 : null)
    }
    data = [...data.splice(selectedIndex), ...data.splice(0, selectedIndex)]
    setReleases(data)
  }

  useEffect(() => {
    getReleases(1)
    window.scrollTo(0, 0);
    if(!!window.location.hash.replace(/^#/, '')) {
      setListViewActive(false)
    }
  }, [])

  useEffect(() => {
    if(releases) {
      window.location.hash = releases[activeIndex].slug;
    }
  }, [activeIndex, activeListIndex])

  useEffect(() => {
    if(savedReleases) {
      processReleasesForSlideshow([...savedReleases], URL_HASH)
    }
  }, [savedReleases])

  const handleSelectFromList = (i) => {
    processReleasesForSlideshow([...savedReleases], savedReleases[i].slug)
    setActiveIndex(0)
    setActiveListIndex(i)
    setListViewActive(false);
  }

  return (
    <div className={'min-h-[100vh] bg-[#ffffee] relative'}>
    {!listViewActive && <p onClick={() => setListViewActive(true)} className={'cursor-pointer z-[999999999999] md:right-[calc(50vw+3rem)] left-8 md:right-32 top-[68px] border border-strange-green/25 py-1 px-2  md:top-12 text-strange-green absolute text-md md:text-xl md:w-[240px] uppercase md:left-auto md:right-[50vw+rem] md:text-center font-bold font-print'}>see all releases</p>}
      {listViewActive &&
        <div className={'w-[100vw] md:w-[50vw] px-10 md:px-0 flex items-center justify-center h-full fixed bg-[#ffffee] z-[99999]'}>
          <div className={'releases-list overflow-y-scroll max-h-[75vh] md:px-[56px] gap-4'}>
          {savedReleases && savedReleases.map((release, i) => {
            return (
              <div onClick={() => handleSelectFromList(i)}  className={`cursor-pointer`}>
              <p className={`cursor-pointer hover:text-strange-green font-print italic px-8 py-4 lg:py-2`} dangerouslySetInnerHTML={{__html: release.title}}></p>
              </div>
            )
          })}
          </div>
        </div>
      }
      {releases && <CarouselInfo slide={activeIndex !== null ? releases[activeIndex] : null} />}
      <div className={'carousel-container w-full min-h-[100vh] mx-auto '}>
      {!listViewActive && releases && <CarouselSlider carousel={releases} currentSlide={activeIndex} handleCurrentSlide={(e) => setActiveIndex(e)}/>}
      </div>
    </div>
  );
}

export default Releases;