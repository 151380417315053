import axios from 'axios';
const WP_BASE_URL= process.env.REACT_APP_WP_API_BASE_URL;
// const MAILCHIMP_API_KEY = process.env.REACT_APP_MAILCHIMP_API_KEY;
// const MAILCHIMP_REGION = process.env.REACT_APP_MAILCHIMP_REGION;
// const MAILCHIMP_LIST_ID = process.env.REACT_APP_MAILCHIMP_LIST_ID;
// const EVENTBRITE_API_KEY = process.env.REACT_APP_EVENTBRITE_API_KEY;
// const EVENTBRITE_ORGANISATION_ID = process.env.REACT_APP_EVENTBRITE_ORGANISATION_ID;
// const REACT_APP_SPOTIFY_CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
// const REACT_APP_SPOTIFY_SECRET_ID = process.env.REACT_APP_SPOTIFY_SECRET_ID;

//    ╔═══════════════╗
//           BASE
//    ╚═══════════════╝
const invokeWebService = async (url, method, data, headers, returnHeader, content) => {
  try {
    const token = localStorage.getItem('key')
    const response = await axios({
      method,
      url:  url,
      data: data || {},
      headers: {
        "Authorization": headers,
        "Content-Type": content ? content : 'application/json',
      },
      timeout: 90000,
    });
    console.log(response);

    return returnHeader ? {data: response.data, headers: response.headers} : response.data;
  } catch(error) {
    // console.log(error.message)
    if (error?.response?.data?.error?.message) {
      throw new Error(error.response.data.error.message);
    }
  }
}

// export const getEventBriteEvents = () => {
//   return invokeWebService(`https://www.eventbriteapi.com/v3/organizations/${EVENTBRITE_ORGANISATION_ID}/events/`, 'GET', null, `token: ${EVENTBRITE_API_KEY}`);
// }
//
// export const authorizeSpotify = () => {
//   return invokeWebService(`https://api.spotify.com/v1/shows/38bS44xjbVVZ3No3ByF1dJ/episodes`, 'GET', null, null, null, "x-www-form-urlencoded")
// }
//
// export const getSpotifyShows = () => {
//   return invokeWebService(`https://api.spotify.com/v1/shows/38bS44xjbVVZ3No3ByF1dJ/episodes`, 'POST', null, null, null, "x-www-form-urlencoded")
// }
//
// export const joinMailchimpMailingList = (email) => {
//   return invokeWebService(`https://${MAILCHIMP_REGION}.api.mailchimp.com/3.0/lists/${MAILCHIMP_LIST_ID}/members`, 'POST', {email_address: email}, `apiKey ${MAILCHIMP_API_KEY}`)
// }

export const getCategories = async() => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/categories?_fields=id,name,slug`, 'GET')
}

export const getCategoriesByParent = async(parent) => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/categories?parent=${parent}&_fields=id,name,slug`, 'GET')
}

export const getPosts = async(page, category, tags, perPage) => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/posts?_embed&page=${page}&per_page=${perPage ? perPage : '100'}${category?`&categories=${category}`:''}${tags?`&tags=${tags}`:''}`, 'GET', null, false, true)
}

export const getPage = async(page) => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/pages/${page}?_embed&acf_format=standard `, 'GET');
}

export const getAuthors = async() => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/users`, 'GET');
}

export const getTags = async() => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/tags`, 'GET');
}

export const getPostsByTag = async(tag) => {
  return invokeWebService(`wp-json/wp/v2/posts?_embed&page=1&per_page=100&categories=2&tags=${tag}`, 'GET', null, false, true)
}

export const getPostBySlug = async(slug) => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/posts?_embed&slug=${slug}`)
}

export const getPostById = async(id) => {
  return invokeWebService(`${WP_BASE_URL}wp-json/wp/v2/posts?_embed&include=${id}`)
}
