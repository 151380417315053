import {useState, useEffect} from 'react'
import placeholder from '../assets/images/43-placeholder.png'
import arrowRight from '../assets/images/arrow-right.png';
import DOMPurify from 'dompurify';
import {getPage, getPosts} from '../api/api'

export const Events = () =>  {

  const [showcases, setShowcases] = useState(null)
  const [eventsPage, setEventsPage] = useState(null)
  const PAGE_ID = 288;
  const CATEGORY_ID = 3;

  const getEvents = (page) => {
    getPosts(page, CATEGORY_ID).then(result => {
      let data = result.data.map(post => ({
        date: post.acf.event_date,
        title: post.acf.event_title,
        artists: post.acf.event_artists,
        city: post.acf.event_city,
        country: post.acf.event_country,
        venue: post.acf.event_venue,
        tickets: post.acf.event_tickets,
      }))
      setShowcases(data)
    })
  }

  const getEventsPage = () => {
    getPage(PAGE_ID).then(result => {
      setEventsPage({
        desktop: result.acf['desktop_background'],
        mobile: result.acf['mobile_background']
      })
    })
  }

  useEffect(() => {
    getEvents(1)
    getEventsPage()
    window.scrollTo(0, 0);
  }, [])


  return (
    <div className={' min-h-[100vh] flex justify-center w-full items-center'}>
    {eventsPage && <div className={'hidden md:block fixed w-full h-[100vh] overflow-hidden z-[0]'}><img src={eventsPage.desktop} className={'w-full absolute left-0 top-0 -z-[0]'} /></div>}
    {eventsPage && <div className={'block md:hidden fixed w-full h-[100vh] overflow-hidden z-[0]'}><img src={eventsPage.mobile} className={'w-full absolute left-0 top-0 -z-[0]'} /></div>}
    <div className={''}>
      <h1 className={'z-[99] relative text-6xl font-scum text-strange-green w-full inline-block mb-8 py-2'}>Events</h1>
      {
        showcases &&
        <table className={'px-4 z-[99] relative blur-panel bg-white/75 w-full mx-auto font-print border border-strange-green'}>
          <tr className={'border-b border-strange-green/50'}>
            <td className={'pl-8 py-2'}>Date</td>
            <td className={'pl-8 py-2'}>Title</td>
            <td className={'pl-8 py-2'}>Artists</td>
            <td className={'pl-8 py-2'}>Location</td>
            <td className={'py-2 text-center'}></td>
          </tr>
          { showcases.map((showcase, i) => {
            return (
              <tr className={`border-b  text-sm md:text-md ${i == showcases.length - 1 ? 'border-strange-green' : 'border-strange-green/25'}`}>
                <td  className={'py-8 lg:px-8 md:px-4 sm:px-2'}>{showcase.date}</td>
                <td  className={'py-8 lg:px-8 md:px-4 sm:px-2'}>{showcase.title}</td>
                <td  className={'py-8 lg:px-8 md:px-4 sm:px-2'}>{showcase.artists}</td>
                <td  className={'py-8 lg:px-8 md:px-4 sm:px-2'}>{showcase.venue}, {showcase.country}, {showcase.city}</td>
                <td  className={'py-8 lg:px-8 md:px-4 sm:px-2'}>{showcase.tickets && <a href={showcase.tickets} target={'_blank'}><img src={arrowRight} className={' w-[64px] translate-y-4 h-auto strange-green-filter'} /></a>}</td>
              </tr>
            )
          })
          }
        </table>
      }
      </div>
    </div>
  );
}

export default Events;
