import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import SlickSlider from "./subcomponents/SlickSlider.js";
import CarouselSliderItem from "./subcomponents/CarouselSliderItem.js";
import carouselSliderData from './subcomponents/CarouselSliderData.json';
import './CarouselSlider.scss'
import cleartape from '../../assets/images/clear-tape.png'
import crack from '../../assets/images/bg-crack.png'
import marks from '../../assets/images/wall-marks-green.png';
import drips from '../../assets/images/wall-drips-green.png';
import arrowLeft from '../../assets/images/arrow-left.png';
import arrowRight from '../../assets/images/arrow-right.png';

const CarouselSlider = ({carousel, handleCurrentSlide}) => {
    const [currentSlide, setCurrentSlide] = useState(null)
    const NextArrow = ({className, onClick}) => {
        return (
            <button className={`arrow-right z-[999999] slick-btn ${className}`} onClick={onClick}>
                <img className={'strange-green-filter'} src={arrowRight} />
            </button>
        );
    };

    const PrevArrow = ({className, onClick}) => {
        return (
            <button className={`arrow-left z-[999999] slick-btn ${className}`} onClick={onClick}>
                <img className={'strange-green-filter'} src={arrowLeft} />
            </button>
        );
    };

    const handleAfterChange = (currentSlide) => {
      setCurrentSlide(currentSlide)
    };

    useEffect(() => {
      if(currentSlide !== undefined && currentSlide !== null) {
        handleCurrentSlide(currentSlide)
      }
    }, [currentSlide])

    const settings = {
        slidesToShow: 2,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>,
        dots: false,
        afterChange: handleAfterChange,
        responsive: [
            {
                "breakpoint": 801,
                "settings": {
                    slidesToShow: 2
                }
            },
            {
                "breakpoint": 577,
                "settings": {
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <div className="md:min-h-[100vh] py-0 border-box bk-carousel-slider bg_color--1 section-separator-with--border slick-arrow-hover ">
        {/*<p>{currentSlide}</p>*/}
            <Container className={'min-h-[33.33vh] md:min-h-[100vh] px-4 pt-20 md:p-0'}>
                <Row className={'min-h-[33.33vh] md:min-h-[100vh]'}>
                    <Col xs={12} className="min-h-[33.33vh] md:min-h-[100vh]">
                        <SlickSlider settings={settings}
                                     classes="min-h-[33.33vh] md:min-h-[100vh]"

                                     >
                            {carousel.map((carousel, i) => (
                              <>
                              <div className={`min-h-[33.33vh] md:min-h-[100vh] flex justify-center items-center  ${i % 2 == 0 ? '':''}`}>
                              {i % 2 == 0 && <div className={` absolute w-[720px] mix-blend-hard-light contrast-[10] brightness-[2] opacity-10 -left-[360px] -z-[1] `}><img className={`translate-x-[${Math.random(0,i)}] rotate-[${i * 45}deg]`} src={crack}/></div>}
                              <div className={'max-w-[480px]'}>
                                <CarouselSliderItem
                                    key={carousel.id}
                                    center={false}
                                    title={carousel.title}
                                    content={carousel.content}
                                    thumb={carousel.thumb}
                                />
                              </div>
                              </div>
                              </>
                            ))}
                        </SlickSlider>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CarouselSlider;
