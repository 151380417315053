import {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom';
import sixteennine from '../assets/images/169-placeholder.png'
import placeholder from '../assets/images/43-placeholder.png'
import placeholder2 from '../assets/images/43-placeholder-2.png'
import tape from '../assets/images/white-tape.png'
import cleartape from '../assets/images/clear-tape.png'
import crack from '../assets/images/bg-crack.png';
import DOMPurify from 'dompurify';
import sticker from '../assets/images/sticker.png'
import paint from '../assets/images/spray-paint.png';
// import strangeTherapy from '../assets/images/strange-therapy.png';
import strangeTherapy from '../assets/images/final-logo.png';
import mutatedCentipede from '../assets/images/mutated-centipede.png';
import flowerFence from '../assets/images/flower-fence.png';

import metalRing from '../assets/images/metal-ring.png';
import {getPage, getPosts, getPostById} from '../api/api'
import cross from '../assets/images/cross.png'
import menu from '../assets/images/menu-icon.png';
import play from '../assets/images/strange-play-icon.png';



export const Home = ({handleMenuIconClick, hasSeenIntro, handleHasSeenIntro}) =>  {
  const [isIntroClicked, setIsIntroClicked] = useState(hasSeenIntro)
  const [isIntroStageOneAnimated, setIsIntroStageOneAnimated] = useState(hasSeenIntro)
  const [isIntroStageTwoAnimated, setIsIntroStageTwoAnimated] = useState(false)
  const [singlePostActiveIndex, setSinglePostActiveIndex] = useState(null)
  const [isMenuActive, setIsMenuActive] = useState(false)
  const [isScrolledDown, setIsScrolledDown] = useState(false)

  const [latestReleases, setLatestReleases] = useState(null);
  const [latestEvents, setLatestEvents] = useState(null)

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const scrollRef = useRef(0)

  const [homePage, setHomePage] = useState(null)
  const PAGE_ID = 247;
  const NEWS_CATEGORY_ID = 4;
  const RELEASES_CATEGORY_ID = 5;
  const EVENTS_CATEGORY_ID = 3;

  const [newsPosts, setNewsPosts] = useState(null)

  const getHomePageContent = () => {
    getPage(PAGE_ID).then(result => {
      console.log(result)
      setHomePage({
        slides: [
          {
            title: result.acf.slider_one.title,
            label: result.acf.slider_one.label,
            image: result.acf.slider_one.featured_image,
            slug: result.acf.slider_one.featured_post.post_name,
            vertical: result.acf.slider_one.vertical_artwork
          },
          {
            title: result.acf.slider_two.title,
            label: result.acf.slider_two.label,
            image: result.acf.slider_two.featured_image,
            slug: result.acf.slider_two.featured_post.post_name,
            vertical: result.acf.slider_two.vertical_artwork
          },
          {
            title: result.acf.slider_three.title,
            label: result.acf.slider_three.label,
            image: result.acf.slider_three.featured_image,
            slug: result.acf.slider_three.featured_post.post_name,
            vertical: result.acf.slider_three.vertical_artwork
          },
        ],
        about: result.acf.about,
      })
    })
  }

  const getNewsPosts = (page) => {
    getPosts(page, NEWS_CATEGORY_ID).then(result => {
      if(result) {
        console.log(result)
        let newsData = result.data.map((post, i) => ({
          title: post.title.rendered,
          content: post.content.rendered,
          image: post._embedded['wp:featuredmedia'][0].source_url,
          excerpt: post.excerpt.rendered,
          slug: post.slug
        }))
        setNewsPosts(newsData.slice(0,3))
        console.log(newsData)
      }
    })
  }

  const getReleases = (page) => {
    getPosts(page, RELEASES_CATEGORY_ID, null, 3).then(result => {
      let data = result.data.map(post => ({
        id: post.id,
        title: post.title.rendered,
        subtitle: post.acf.post_subtitle,
        excerpt: post.content.excerpt,
        content: post.content.rendered,
        thumb: post._embedded['wp:featuredmedia'][0].source_url,
        slug: post.slug,
        album: post.acf.release_album,
        artist: post.acf.release_artist,
        video_embed: post.acf.release_video,
        bandcamp_embed: post.acf.bandcamp_embed,
        intro_photo: post.acf.introduction_image
      }))
      setLatestReleases(data);
    })
  }

  const getEvents = (page) => {
    getPosts(page, EVENTS_CATEGORY_ID, null, 1).then(result => {
      let data = result.data.map(post => ({
        date: post.acf.event_date,
        title: post.acf.event_title,
        artists: post.acf.event_artists,
        city: post.acf.event_city,
        country: post.acf.event_country,
        venue: post.acf.event_venue
      }))
      setLatestEvents(data)
      console.log(data);
    })
  }

  useEffect(() => {
    getHomePageContent()
    getNewsPosts(1)
    getReleases(1)
    getEvents(1)
    window.scrollTo(0, 0);
    setTimeout(() => {
      // setIsIntroClicked(true)
    }, 1000)
    return () => { }
  }, [])


  useEffect(() => {
    setTimeout(function () {
      if(isIntroClicked) {
        setIsIntroStageOneAnimated(true)
        handleHasSeenIntro(true)
      }
    }, 1000);
    return () => { }
  }, [isIntroClicked])



  return (
    <div className={'min-h-[calc(100vh)] w-full bg-[#ffd]/25 flex items-center  overflow-hidden'}>
      <div onClick={() => setIsIntroClicked(true)} className={`cursor-pointer hover:opacity-50 fixed w-full h-full top-0 left-0 bg-[#ffffee]/50 blur-panel   transition duration-[1000ms] ease-out flex justify-center items-center ${isIntroClicked ? 'opacity-0' : ''} hover:dithered-background-no-animation ${isIntroStageOneAnimated ? '-z-[999]' : 'z-[99999999999999]'}`}>
          <p className={`transition duration-1000 ${isIntroClicked ? '-translate-y-12 opacity-0' : ''} absolute font-print text-2xl text-strange-green break-words w-[10px] text-center`}>STRANGE&nbsp;<br/>THERAPY</p>
          <div className={`transition duration-1000 absolute left-[50vw] w-[720px] -z-[1] rotate-90 mix-blend-hard-light saturate-[2] ${isIntroClicked ? '-translate-y-12 opacity-0' : ''}`}><img className={'absolute opacity-75 w-full blur-[1px] animate-spin-slow'} src={metalRing} /></div>

      </div>
      <div className={`2xl:max-w-screen-2xl xl:max-w-screen-xl lg:max-w-screen-xl w-full mt-20 lg:mt-0  pb-16 md:pb-0 relative mx-auto border-box px-2 md:px-16 transition duration-[3000ms] ease-in-out ${isIntroClicked ? 'opacity-100' : 'opacity-50'}`}>
          
          <div className={`transition duration-1000 ${!isIntroStageOneAnimated ? 'opacity-0' : ''} bg-[#ffffee]/80 blur-panel border-y border-r border-strange-green border-strange-green mx-4 xs:mx-8 xs:flex justify-between relative z-[9]`}>
            <div className={'w-full flex flex-start'}>
            <div className={'inline-block w-[64px] border-x border-strange-green'}>
              <img className={'w-full h-auto'} src={strangeTherapy} />
            </div>
            <div className={'font-print w-full text-strange-green px-4 flex flex-col justify-center pt-1 ml-2 xs:ml-0 xs:mt-0'}>
              <p className={'text-sm md:text-md w-full italic tracking-wide'}><span>Strange Therapy</span> // Struggling Electronics</p>
              {/*<ul className={' list-style-type-none flex gap-4 py-1 tracking-wide'}>
                <Link to={'/about'}><li className={'hidden xs:inline-block'}>Label</li></Link>
                <Link to={'/artists'}><li className={'hidden xs:inline-block'}>Artists</li></Link>
                <Link to={'/discography'}><li className={'hidden xs:inline-block'}>Discography</li></Link>
                <Link to={'/events'}><li className={'hidden xs:inline-block'}>Events</li></Link>
              </ul>*/}
              <div>
              </div>
            </div>
            </div>
            <div className={'flex justify-between w-full'}>
            <div className={'w-full flex flex-end'}>
              {
              latestEvents && 
            <div className={'hidden lg:inline-block lg:w-full pr-2  float-right mt-[0.25rem] italic text-right text-sm font-print text-strange-green'}>
              <p className={'text-[9px] tracking-wide font-bold mb-[0.33rem]'}>NEXT EVENT</p>
              <p>: <span className={'tracking-wide'}>{latestEvents[0].date}</span> {latestEvents[0].title}, {latestEvents[0].city} @ {latestEvents[0].venue}</p>
            </div>
              }
            </div>
            <div className={'hidden w-[64px] cursor-pointer border-x border-strange-green'}>
                <img onClick={() => handleMenuIconClick()} src={menu} className={'scale-x-[1.5] scale-y-[2] translate-y-[11px] -translate-x-[5px] strange-green-filter'} />
            </div>
            </div>
          </div>
            {
              latestEvents && 
            <div className={`mx-4 xs:mx-8 transition duration-1000 ${!isIntroStageOneAnimated ? 'opacity-0' : ''}`}>
              <div className={'block px-2 py-1 bg-[#ffffee] border border-strange-green lg:hidden  mt-1 italic  text-sm font-print text-strange-green'}>
                <p className={'text-[9px] tracking-wide font-bold'}>NEXT EVENT</p>
                <p>: <span className={'tracking-wide'}>{latestEvents[0].date}</span> {latestEvents[0].title}, {latestEvents[0].city} @ {latestEvents[0].venue}</p>
              </div>
            </div>
              }
          
          <div className={'absolute right-[33.333vw] bottom-[0vh] w-[720px] -z-[1] rotate-90 mix-blend-hard-light saturate-[2]'}><img className={'absolute opacity-75 w-full blur-[1px] animate-spin-slow'} src={metalRing} /></div>

          <img src={mutatedCentipede} className={'z-[0] absolute w-full h-auto mix-blend-hard-light contrast-[10] brightness-[2] -left-[50%] -top-[50%] opacity-5'} />
          <img src={mutatedCentipede} className={'z-[0] absolute w-full h-auto mix-blend-hard-light contrast-[10] brightness-[2] -scale-x-[1] left-[50%] -top-[50%] opacity-5'} />
          <img src={crack} className={'z-[0] absolute w-full h-auto mix-blend-hard-light contrast-[10] brightness-[2] -top-[50%] opacity-5'} />   


          <div className={`lg:grid relative lg:grid-cols-2 mt-1 px-4 xs:px-8 transition duration-1000`}>
            { homePage &&
              <div className={`transition duration-1000 ${!isIntroStageOneAnimated ? 'opacity-0' : ''} w-ful xs:grid xs:grid-cols-3 border border-strange-green`}>
                  <div className={'col-span-2 relative'}>
                    <Link className={''} to={`/discography#${homePage.slides[activeSlideIndex].slug}`}>
                      <div className={`hover:dithered-background w-full absolute h-full`}>

                      </div>
                      <img className={'w-full h-full xs:border-r border-strange-green'} src={homePage.slides[activeSlideIndex].image} />
                    </Link>
                    <h2 className={'absolute bottom-4 left-4 p-2 border border-strange-green font-print bg-[#ffffee] text-strange-green italic text-xl'}>{homePage.slides[activeSlideIndex].title}
                    </h2>
                    {homePage.slides[activeSlideIndex].label && <h4 className={'text-md bg-[#ffffee] max-w-[40%] italic absolute top-4 right-4 ml-4 font-print text-strange-green p-1 border border-strange-green'}>{homePage.slides[activeSlideIndex].label}
                    </h4>}
                    {/*<div className={'absolute right-4 bottom-4 z-[99999] w-[50px] h-[50px] bg-[#ffffee] p-1 border-2 border-strange-green'}>
                        <img className={'w-full h-auto strange-green-filter border border-strange-green '} src={play} />
                    </div>*/}
                    <div className={'slider-controls absolute top-4 left-4 flex origin-top-left'}>
                    <div onClick={() => setActiveSlideIndex(activeSlideIndex !== 0 ? activeSlideIndex - 1 : 2)} className={'w-[34px] cursor-pointer bg-[#ffffee] blur-panel border border-strange-green p-1 -scale-x-[1] mr-1'}><img className={'w-full h-auto strange-green-filter '} src={play} /></div>
                    <div onClick={() => setActiveSlideIndex(0)} className={`${activeSlideIndex == 0 ? 'bg-strange-green/30 border-[#ffffee]':'bg-strange-green border-[#ffffee]/50'}  cursor-pointer w-3 h-3 rotate-45 mx-2 border border-strange-green mt-[10px]`}></div>
                    <div onClick={() => setActiveSlideIndex(1)} className={`${activeSlideIndex == 1 ? 'bg-strange-green/30 border-[#ffffee]':'bg-strange-green border-[#ffffee]/50'} cursor-pointer w-3 h-3 rotate-45 mx-2 border border-strange-green mt-[10px]`}></div>
                    <div onClick={() => setActiveSlideIndex(2)} className={`${activeSlideIndex == 2 ? 'bg-strange-green/30 border-[#ffffee]':'bg-strange-green border-[#ffffee]/50'}  cursor-pointer w-3 h-3 rotate-45 mx-2 border border-strange-green mt-[10px]`}></div>
                    <div onClick={() => setActiveSlideIndex(activeSlideIndex !== 2 ? activeSlideIndex + 1 : 0)} className={'w-[34px] cursor-pointer bg-[#ffffee] blur-panel border border-strange-green p-1 ml-1'}><img className={'w-full h-auto strange-green-filter'} src={play} /></div>
                    </div>
                  </div>
                  <div className={'col-span-1 hidden xs:block blur-panel bg-[#ffffee]/80'}>
                    <div className={'w-full h-full relative'}>
                        <img className={'w-full h-full'} src={homePage.slides[activeSlideIndex].vertical} />
                        <div className={`hover:dithered-background w-full absolute h-full top-0 z-[9]`}></div>
                    </div>
                    {/*latestReleases && latestReleases.map((release, i) => {
                      return (
                        <div className={`${i !== 0 ? '' : ''} relative`}>
                          <Link className={''} to={`/discography#${release.slug}`}>
                            <div className={'absolute right-0 text-right top-[6px]'}>
                              <div>
                                <p className={'border border-strange-green/50 bottom-0 mx-2 bg-[#ffffee] inline-block px-1 font-print italic text-xs text-strange-green'} dangerouslySetInnerHTML={{__html: release.album}}></p>
                              </div>
                              <p className={'border border-strange-green/50 bottom-0 mx-2 bg-[#ffffee] px-1 inline-block font-print italic text-xs text-strange-green'} dangerouslySetInnerHTML={{__html: release.artist}}></p>
                            </div>
                            <div className={`p-[19px]`}>

                              <img className={'border border-strange-green/25'} src={release.thumb} />
                            </div>
                          </Link>
                        </div>
                      )
                    })*/}
                  </div> 
              </div>
            }
            <div className={'relative  h-auto col-span-2 md:col-span-1'}>
            <div className={`grid lg:grid-cols-12 blur-panel h-full transition duration-1000 ${!isIntroStageOneAnimated ? 'opacity-0' : ''}`}>
            
              <div className={'col-span-12 lg:col-span-5 '}>
                <div className={'h-full bg-[#ffffee]/90 border-x lg:border-y lg:border-x-[#ffffee] lg:border-y-strange-green border-x-strange-green py-12 lg:py-0'}>
                  <div className={'w-[182px] lg:w-full xl:w-[236px] mx-auto px-4 py-2 translate-x-[2px] lg:-translate-x-[2px]'}>
                    <img src={strangeTherapy} />
                  </div>
                  {homePage && homePage.about && <p className={'home-about-paragraph font-print text-xs leading-loose italic px-6 text-center mt-2 max-w-[320px] mx-auto'} dangerouslySetInnerHTML={{__html: homePage.about}}></p>}
                </div>
              </div>
              <div className={'col-span-12 lg:col-span-7 h-full flex flex-col relative space-around bg-[#ffffee]/90 blur-panel-max border-b border-r border-strange-green border-l relative z-[0]'}>
              {
                singlePostActiveIndex !== null &&
                <div className={'bg-white absolute w-full overflow-y-scroll h-full top-0 left-0 border-t border-t-strange-green z-[999999999999999]'}>
                  <button onClick={() => setSinglePostActiveIndex(null)} className={"cursor-pointer absolute z-[99999999999999999999999] uppercase text-strange-green border-b border-b-strange-green/25 border-b-2 pb-0 font-print ml-4 mt-4 font-bold text-sm"}>Return</button>

                  <h2 className={'font-print text-strange-green font-bold text-4xl mt-12 px-4'}>{newsPosts[singlePostActiveIndex].title}</h2>
                  <div className={'home-blog-single-content font-print'} dangerouslySetInnerHTML={{__html: newsPosts[singlePostActiveIndex].content}}>
                  </div>
                </div>
              }
              {
                newsPosts && newsPosts.map((p, i) => {
                  return (
                    <>
                    
                        <div onClick={() => setSinglePostActiveIndex(i)} className={`${i == 0 ? 'border-t' : '' } cursor-pointer relative overflow-hidden h-[320px] sm:h-[400px] md:h-[520px] lg:h-[50%] border-b flex items-end blur-panel  border-strange-green blur-panel`}>
                          <div className={'absolute w-full h-full hover:dithered-background z-[999999999999999999999999]'}>
                          </div>
                          <div>
                              <img src={p.image} className={'absolute top-0 w-full'} />
                              <p className={'italic absolute bottom-4 left-4 max-w-[240px] bg-[#ffe] border border-strange-green blur-panel w-full subpixel-antialiased z-[99999999999999999999999] font-print text-md md:text-sm text-strange-green py-2 px-3'}><span dangerouslySetInnerHTML={{__html: p.excerpt}}></span></p>
                          </div>
                        </div>

                      
                    </>
                  )
                })
              }
              <div className={'w-full py-4 grid grid-cols-1 xs:grid-cols-2 bg-[#ffffee]/75 blur-panel'}>
                <div className={'flex flex-col items-center'}>
                  <h6 className={'font-print ml-4 italic text-strange-green text-sm mb-1'}>Support us on</h6>
                  <a href={'https://strangetherapy.bandcamp.com/'} target={'_blank'}><h4 className={'font-print bg-[#ffffee]/50 blur-panel text-lg text-strange-green px-12 lg:px-4 2xl:px-12 border border-strange-green/25 mb-2 py-1 mt-1 mx-4 inline-block italic'}>Bandcamp</h4></a>
                </div>
                <div className={'flex flex-col items-center'}>
                  <h6 className={'font-print ml-4 italic text-strange-green text-sm mb-1'}>Follow us on</h6>
                  <a href={'https://www.instagram.com/strangetherapytapes/'} target={'_blank'}><h4 className={'font-print bg-[#ffffee]/50 blur-panel text-lg text-strange-green px-12 lg:px-4 2xl:px-12 border border-strange-green/25 mb-2 py-1 mt-1 mx-4 inline-block italic'}>Instagram</h4></a>
                </div>
              </div>
              </div>
            </div>



              <div className={'absolute left-[66.6vw] top-[32.5vh] w-full scale-[1.5] -z-[1] rotate-90'}><img className={'absolute opacity-75 w-full blur-[2px] animate-spin-slow'} src={metalRing} /></div>
              <div className={'absolute -left-[50vw] -top-[12.5vh] w-full -z-[2] rotate-90 mix-blend-hard-light saturate-[5]'}><img className={'absolute opacity-75 w-full blur-[2px] animate-spin-slow'} src={metalRing} /></div>
            </div>

            <div className={'relative  hidden col-span-1 pt-24 px-24 pt-0'}>
            <div className={'relative z-[9999] w-full h-full'}>
{/*              <div className={'absolute left-0 saturate-[1.25] blur-[2rem] top-0 w-full h-full mix-blend-hard-light bg-gradient-to-br from-gradient-from-green from-0% via-gradient-via-green via-50% to-gradient-to-green to-100%'}></div>*/}
            <img src={placeholder} className={'invert w-full h-auto opacity-90 mix-blend-hard-light'} />
            <div className={'absolute w-full h-[calc(100%-96px)] mix-blend-hard-light opacity-50 top-0 bg-[#b6ffb0]'}></div>
            <div className={'absolute top-0 left-0 w-[80%] ml-[10%] mt-[12.5%]'}>
              <img src={placeholder} className={'absolute w-full h-auto mix-blend-hard-light'} />
            </div>
            </div>
              <div className={'absolute w-[480px] right-0 translate-x-[0.333rem] font-metal text-8xl top-[380px]'}>
                 <h2>Matriarchy Roots</h2>
              </div>
              <div className={'absolute top-[96px] w-[300px] h-[150px] left-[0%] z-[99]'}>
                 <div>
                   <img src={tape} className={'hidden absolute top-0 left-0 w-full z-10'} />
                 </div>
                 <div className={'h-[150px] flex items-center'}>
                   <h3 className={'relative font-print font-bold z-50 pl-4 text-strange-green pr-4 text-2xl bg-white border border-strange-green pr-4 py-2'}>Who do you turn to and are you satisfied with life?</h3>
                 </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Home;
